<template>
  <v-dialog persistent v-model="dialog">
    <v-card class="pa-4 rounded-lg">
      <v-card-title class="subtitle-1 font-weight-medium">
        Duplicar conteúdo?
      </v-card-title>
      <v-divider class="mt-n3 mb-3"></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="pa-1 mb-n6 mt-2">
            <v-alert border="left" colored-border color="#26A69A" dense text>
              Informações a serem
              <strong class="teal--text text--lighten-1 font-weight-black"
                >duplicadas:</strong
              >
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-1">
            <v-checkbox
              v-model="checkbox"
              :label="`Agendamento`"
              disabled
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="checkbox"
              :label="`Cliente`"
              disabled
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="darken-1"
          text
          @click="dialog = false"
          :to="{ name: 'Terminal' }"
          dense
        >
          Cancelar
        </v-btn>
        <v-btn
          dark
          @click="copyTerminal"
          :to="{ name: 'Terminal' }"
          filled
          dense
          color="#26A69A"
        >
          Duplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TerminalService from "./service";
export default {
  name: "CopyTerminal",
  data() {
    return {
      dialog: true,
      checkbox: ["Agendamento", "Cliente"],
    };
  },
  methods: {
    async copyTerminal() {
      await TerminalService.copyTerminal(this.$route.params.id);
      this.$router.push({ name: "Terminal" });
      this.$emit("created", {
        terminal: { name: "" },
        typeForm: "CopyTerminal",
      });
    },
  },
};
</script>
